<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <anomalia-form
          v-if="pregunta && pregunta.pregunta_checklist_ot"
          :id="null"
          :idpregunta-checklist-ot="pregunta.pregunta_checklist_ot.idpregunta_checklist_ot"
          :idtfichero="idtfichero"
          :idgrado-anomalia="routeQuery.idgrado_anomalia"
          :has-insert-anomalia-adjunto-perm="hasInsertAnomaliaAdjuntoPerm"
          :has-agregar-a-definicion-checklist="hasAgregarADefinicionChecklist"
          :has-anomalia-texto-libre="hasAnomaliaTextoLibre"
          :material-afectado="materialAfectado"
          :idtanomalia-checklist="routeQuery.idtanomalia_checklist"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import { TFICHERO } from '@/utils/consts'
import AnomaliaForm from '../components/AnomaliaForm'
import Data from './AnomaliaAddData'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineAnomaliaAdd'

export default {
  components: {
    AnomaliaForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      pageStoreName,
      idtfichero: null,
      pregunta: {},
      hasInsertAnomaliaAdjuntoPerm: true,
      hasAgregarADefinicionChecklist: true,
      hasAnomaliaTextoLibre: true,
      materialAfectado: {},
    }
  },
  computed: {
    nextRoute: get(`${pageStoreName}/nextRoute`),
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    // routeQuery.idgrado_anomalia: grado de anomalía por defecto (form)
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir anomalía'
      this.hasInsertAnomaliaAdjuntoPerm = this.hasInsertPerm(this.permissions.anomaliaAdjunto.id)
      this.hasAgregarADefinicionChecklist = this.hasViewPerm(this.permissions.anomaliaAgregarADefinicionChecklist.id)
      this.hasAnomaliaTextoLibre = this.hasPerm(this.permissions.anomaliaTextoLibre.id)
      const tfichero = await this.$offline.tfichero.rowTficheroClasificacion(
        TFICHERO.clasificacion.adjuntoAnomalia
      )
      this.idtfichero = tfichero[0].idtfichero
      if (this.idtfichero === null) {
        this.$alert.showSnackbarError('No existe un tipo de ficheros definido para anomalías')
      }
      this.pregunta = await this.$offline.preguntaChecklistOt.row(this.routeParams.idpregunta_checklist_ot)
      this.materialAfectado = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(this.routeParams.idpregunta_checklist_ot)
    },
    async submitForm () {
      const idtanomaliaChecklistOt = await Data.insert(
        this,
        this.formData,
        this.routeParams.idpregunta_checklist_ot,
        this.idtfichero,
        this.usuarioIdtecnico,
        this.routeParams.idparte_trabajo,
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.local.preguntaChecklistOt, this.routeParams.idpregunta_checklist_ot)
      this.$appRouter.replace({
        name: 'offline__anomalia-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
          idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
          idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
          idtanomalia_checklist_ot: idtanomaliaChecklistOt,
        },
      })
    },
  },
}
</script>
